import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => {
  return (
    <div style={{height: '100vh', width: '100vw', backgroundColor: 'black', margin: '-8px'}}>
      <SEO title="Black screen to marvel your OLED screens or hunt dust particles" />
    </div>
  );
}

export default NotFoundPage
